.h {
  margin-top: 1em;
  margin-bottom: 0.5em;
  line-height: 1.5;

  /* offset (approximately) with sticky header so jumped-to content isn't hiding behind it.
     note: use rem so it isn't based on the heading's font size. */
  scroll-margin-top: 5.5rem;
}

.h.divider {
  padding-bottom: 0.25em;
  border-bottom: 1px solid var(--colors-kindaLight);
}

.anchor {
  margin: 0 0.4em;
  padding: 0 0.2em;
  color: var(--colors-medium) !important;

  /* overridden on hover below (except on small screens) */
  opacity: 0;
}

/* show anchor link when hovering anywhere over the heading line, or on keyboard tab focus */
.anchor:hover,
.anchor:focus-visible {
  color: var(--colors-link) !important;
}

.h:hover .anchor,
.h:focus-visible .anchor {
  opacity: 1;
}

@media (max-width: 768px) {
  .h {
    scroll-margin-top: 5.5rem;
  }

  .anchor {
    margin: 0 0.2em;
    padding: 0 0.4em;

    /* don't require hover to show anchor link on small (likely touch) screens */
    opacity: 1;
  }
}
