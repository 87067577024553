.flex {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.default {
  width: 100%;
  padding: 1.5em;
}

.container {
  max-width: var(--sizes-maxLayoutWidth);
  margin: 0 auto;
  display: block;
}

.stickyHeader {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.flexedFooter {
  flex: 1;
}
