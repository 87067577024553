.button {
  line-height: 1px;
  cursor: pointer;
}

.button:hover,
.button:focus-visible {
  color: var(--colors-link);
}

.button.copied {
  color: var(--colors-success) !important;
}

.icon {
  width: 1.25em;
  height: 1.25em;
  vertical-align: -0.3em;
}
