.image {
  height: auto;
  max-width: 100%;
  border-radius: var(--radii-corner);
}

.block {
  display: block;
  line-height: 0;

  /* default to centering all images */
  margin: 1em auto;
  text-align: center;
}
