.codeBlock {
  position: relative;
  width: 100%;
  margin: 1em auto;
  color: var(--colors-codeText);
}

.codeBlock .code {
  display: block;
  overflow-x: auto;
  padding: 1em;
  font-size: 0.9em;
  tab-size: 2px;
  background-color: var(--colors-codeBackground);
  border: 1px solid var(--colors-kindaLight);
  border-radius: var(--radii-corner);
  transition:
    background var(--transitions-fade),
    border var(--transitions-fade);
}

.codeBlock :global(.line-number)::before {
  display: inline-block;
  width: 1.5em;
  margin-right: 1.5em;
  text-align: right;
  color: var(--colors-codeComment);
  content: attr(line);
  font-variant-numeric: tabular-nums;
  user-select: none;
}

.codeBlock :global(.code-line):first-of-type {
  margin-right: 3em;
}

.codeBlock.highlight :global(.token.comment),
.codeBlock.highlight :global(.token.prolog),
.codeBlock.highlight :global(.token.cdata) {
  color: var(--colors-codeComment);
}
.codeBlock.highlight :global(.token.delimiter),
.codeBlock.highlight :global(.token.boolean),
.codeBlock.highlight :global(.token.keyword),
.codeBlock.highlight :global(.token.selector),
.codeBlock.highlight :global(.token.important),
.codeBlock.highlight :global(.token.doctype),
.codeBlock.highlight :global(.token.atrule),
.codeBlock.highlight :global(.token.url) {
  color: var(--colors-codeKeyword);
}
.codeBlock.highlight :global(.token.tag),
.codeBlock.highlight :global(.token.builtin),
.codeBlock.highlight :global(.token.regex) {
  color: var(--colors-codeNamespace);
}
.codeBlock.highlight :global(.token.property),
.codeBlock.highlight :global(.token.constant),
.codeBlock.highlight :global(.token.variable),
.codeBlock.highlight :global(.token.attr-value),
.codeBlock.highlight :global(.token.class-name),
.codeBlock.highlight :global(.token.string),
.codeBlock.highlight :global(.token.char) {
  color: var(--colors-codeVariable);
}
.codeBlock.highlight :global(.token.literal-property),
.codeBlock.highlight :global(.token.attr-name) {
  color: var(--colors-codeAttribute);
}
.codeBlock.highlight :global(.token.function) {
  color: var(--colors-codeLiteral);
}
.codeBlock.highlight :global(.token.tag .punctuation),
.codeBlock.highlight :global(.token.attr-value .punctuation) {
  color: var(--colors-codePunctuation);
}
.codeBlock.highlight :global(.token.inserted) {
  color: var(--colors-codeAddition);
}
.codeBlock.highlight :global(.token.deleted) {
  color: var(--colors-codeDeletion);
}
.codeBlock.highlight :global(.token.url) {
  text-decoration: underline;
}
.codeBlock.highlight :global(.token.bold) {
  font-weight: bold;
}
.codeBlock.highlight :global(.token.italic) {
  font-style: italic;
}

.cornerCopyButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.65em;
  background-color: var(--colors-backgroundInner);
  border: 1px solid var(--colors-kindaLight);
  border-top-right-radius: var(--radii-corner);
  border-bottom-left-radius: var(--radii-corner);
  transition:
    background var(--transitions-fade),
    border var(--transitions-fade);
}
