.meta {
  display: inline-flex;
  flex-wrap: wrap;
  font-size: 0.825em;
  line-height: 2.3;
  letter-spacing: 0.04em;
  color: var(--colors-medium);
}

.meta .item {
  margin-right: 1.6em;
  white-space: nowrap;
}

.meta .link {
  color: inherit !important;
}

.meta .icon {
  display: inline;
  width: 1.2em;
  height: 1.2em;
  vertical-align: -0.2em;
  margin-right: 0.6em;
}

.meta .tags {
  white-space: normal;
  display: inline-flex;
  flex-wrap: wrap;
}

.meta .tag {
  text-transform: lowercase;
  white-space: nowrap;
  margin-right: 0.75em;
}
.meta .tag:before {
  content: "\0023"; /* cosmetically hashtagify tags */
  padding-right: 0.125em;
  color: var(--colors-light);
}
.meta .tag:last-of-type {
  margin-right: 0;
}

.title {
  margin: 0.3em 0 0.5em -1px; /* misaligned left margin, super nitpicky */
  font-size: 2.1em;
  line-height: 1.3;
  font-weight: 700;
}

.title code {
  margin: 0 0.075em;
}

.title .link {
  color: var(--colors-text) !important;
}

@media (max-width: 768px) {
  .title {
    font-size: 1.8em;
  }
}
