.section {
  font-size: 1.1em;
  line-height: 1.1;
  margin: 2.4em 0;
}

.section:first-of-type {
  margin-top: 0;
}
.section:last-of-type {
  margin-bottom: 0;
}

.year {
  font-size: 2.2em;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0.5em;
}

.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.post {
  display: flex;
  line-height: 1.75;
  margin-bottom: 1em;
}

.post:last-of-type {
  margin-bottom: 0;
}

.postDate {
  width: 5.25em;
  flex-shrink: 0;
  color: var(--colors-medium);
}

@media (max-width: 768px) {
  .section {
    margin: 1.8em 0;
  }

  .year {
    font-size: 2em;
  }
}
