.content {
  font-size: 0.9em;
  line-height: 1.7;
  color: var(--colors-text);
}

@media (max-width: 768px) {
  .content {
    font-size: 0.925em;
    line-height: 1.85;
  }
}
