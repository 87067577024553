.header {
  width: 100%;
  height: 4.5em;
  padding: 0.7em 1.5em;
  border-bottom: 1px solid var(--colors-kindaLight);
  background-color: var(--colors-backgroundHeader);
  transition:
    background var(--transitions-fade),
    border var(--transitions-fade);
  z-index: 9999px;

  /* blurry glass-like background effect (except on firefox...?) */
  backdrop-filter: saturate(180%) blur(5px);
}

.selfieImage {
  width: 50px;
  height: 50px;
  border: 1px solid var(--colors-light);
  border-radius: 50%;
}

.selfieLink {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  color: var(--colors-mediumDark) !important;
}

.selfieLink:hover,
.selfieLink:focus-visible {
  color: var(--colors-link) !important;
}

.name {
  margin: 0 0.6em;
  font-size: 1.15em;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: var(--sizes-maxLayoutWidth);
  margin: 0 auto;
}

@media (max-width: 768px) {
  .header {
    padding: 0.75em 1.25em;
    height: 5.9em;
  }

  .selfieImage {
    width: 70px;
    height: 70px;
    border-width: 2px;
  }

  .selfieLink:hover .selfieImage,
  .selfieLink:focus-visible .selfieImage {
    border-color: var(--colors-linkUnderline);
  }

  .name {
    display: none;
  }

  .menu {
    max-width: 325px;
  }
}

@media (max-width: 380px) {
  .menu {
    max-width: 225px;
  }
}
